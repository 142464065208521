import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="404: Not found"
      description="The page you are trying to access is unavailable or no longer exists."
    />
    <div className="container">
      <h1>Not found</h1>
      <p>
        The page you are trying to access is unavailable or no longer exists.
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
